app-tool-tip{
  .ngxp__container{
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    color: #fff;
    background-color: #000;
    font-size: 0.8rem;
    z-index: 10;
    line-height: 1rem;
    font-weight: 100;
    &[aria-hidden="true"] {
            visibility: hidden;
        }
  }
  .ngxp__inner{
    max-width: 20rem;
    width: 20rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ngxp__container>.ngxp__arrow:before {
    background-color: #000;
  }
}
