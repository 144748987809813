
.get-report-modal{
  max-width: 62rem !important;
  .mat-dialog-container {
    padding: 0;
    height: auto;
  }

  @media only screen and (max-width: 1200px) {
    min-width: 46.5rem !important;
    mat-dialog-container{
      max-height: 80vh;
      height: auto;
    }
  }
  @media only screen and (max-width: 700px) {
    max-width: 100% !important;
    min-width: auto !important;
    width: 100%;
    max-height: 100%;
    height: 100%;

    mat-dialog-container{
      height: 100%;
      max-height: 100%;
      border-radius: 0 !important;
    }

    //for ultra narrow screen
    mat-checkbox{
      label{
        &.mat-checkbox-layout {
          white-space: initial;
        }
      }
    }
  }
}

body.get-report-modal-open {
  >div:nth-of-type(3) {
    >div:nth-of-type(2) {
      @media only screen and (max-width: 480px) {
        position: fixed !important;
      }
    }
  }
}
