
delete-company-modal {
  .mat-dialog-container {
    border-radius: 0.75rem !important;
    padding: 2.25rem 2rem !important;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
