/*The div:not() prevents these properties from being applied to other angular divs*/
/*The captcha div does not have easy identification*/

@media only screen and (min-width: 800px) {
  
  body > div:not( [class*="cdk-"], [class*="QSI"] ){
    top:50% !important;
    left:50% !important;
    transform: translateY(-50%) translateX(-50%);
  }
  
}